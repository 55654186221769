'use-client';
import * as React from 'react';
import classNames from 'clsx';
import type { IVideoLegacyProps } from '../VideoLegacy.types';
import { useChangedEffect } from '../../../providers/useChangedEffect';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';
import style from './style/VideoLegacy.scss';
import services from './services';
import { TestIds } from './constants';
import { renderSkinBorders } from './skinParts';

const VideoLegacy: React.FC<IVideoLegacyProps> = props => {
  const {
    id,
    className,
    skin = 'VideoDefault',
    videoType,
    isPlayingAllowed,
    autoplay,
    showControls,
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const frameRef = React.useRef<HTMLIFrameElement>(null);

  const service = services[videoType];
  const frameUrl = service.getUrl(props);
  const frameTitle = service.title;

  useChangedEffect(isPlayingAllowed, () => {
    if (frameRef.current) {
      frameRef.current.src = frameUrl;
    }
  });

  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className: classNames(className, style.root, style[skin]),
  });

  return (
    <div
      {...baseComponentProps}
      data-player={videoType}
      data-showcontrols={showControls}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {renderSkinBorders(skin)}
      <div className={style.videoFrame} tabIndex={0}>
        <iframe
          ref={frameRef}
          data-src={frameUrl}
          src={
            frameUrl /* Data-src is the only src parameter which is used in original component. But storybook does not work this way. It needs src to be set */
          }
          height="100%"
          width="100%"
          frameBorder={0}
          title={frameTitle}
          aria-label={frameTitle}
          data-testid={TestIds[videoType]}
          allowFullScreen
          /** Autoplay might fail in some browsers without whitelisting this policy
           * Reference: https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide#the_autoplay_feature_policy */
          allow={autoplay ? "autoplay 'src'" : undefined}
        />
      </div>
      <div id="preview" style={{ display: 'none' }}></div>
    </div>
  );
};

export default VideoLegacy;
